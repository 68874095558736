<template>
  <div class="container mx-auto max-w-4xl my-8">
    <section v-if="editor" class="buttons flex items-center flex-wrap gap-x-4 border-t border-r border-l border-gray-400 p-4">
      <button @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'bg-gray-200': editor.isActive('bold') }" class="p-1">
        <font-awesome-icon :icon="['fas', 'bold']" />
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'bg-gray-200': editor.isActive('italic') }" class="p-1">
        <font-awesome-icon :icon="['fas', 'italic']" />
      </button>
      <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'bg-gray-200': editor.isActive('underline') }" class="p-1">
        <font-awesome-icon :icon="['fas', 'underline']" />
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'bg-gray-200': editor.isActive('strike') }" class="p-1">
        <font-awesome-icon :icon="['fas', 'strikethrough']" />
      </button>
      <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'bg-gray-200': editor.isActive('paragraph') }" class="p-1">
        <font-awesome-icon :icon="['fas', 'p']" />
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'bg-gray-200': editor.isActive('heading', { level: 1 }) }" class="p-1">
        H1
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'bg-gray-200': editor.isActive('heading', { level: 2 }) }" class="p-1">
        H2
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'bg-gray-200': editor.isActive('heading', { level: 3 }) }" class="p-1">
        H3
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'bg-gray-200': editor.isActive('heading', { level: 4 }) }" class="p-1">
        H4
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'bg-gray-200': editor.isActive('heading', { level: 5 }) }" class="p-1">
        H5
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'bg-gray-200': editor.isActive('heading', { level: 6 }) }" class="p-1">
        H6
      </button>
      <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'bg-gray-200': editor.isActive('bulletList') }" class="p-1">
        <font-awesome-icon :icon="['fas', 'list-ul']" />
      </button>
      <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'bg-gray-200': editor.isActive({ textAlign: 'left' }) }" class="p-1">
        <font-awesome-icon :icon="['fas', 'align-left']" />
      </button>
      <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'bg-gray-200': editor.isActive({ textAlign: 'center' }) }" class="p-1">
        <font-awesome-icon :icon="['fas', 'align-center']" />
      </button>
      <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'bg-gray-200': editor.isActive({ textAlign: 'right' }) }" class="p-1">
        <font-awesome-icon :icon="['fas', 'align-right']" />
      </button>
      <input
          type="color"
          @input="editor.chain().focus().setColor($event.target.value).run()"
          :value="editor.getAttributes('textStyle').color || '#000000'"
      >
    </section>
    <editor-content :editor="editor" />
  </div>
</template>

<script setup>
/*
import TextEditor from "@/components/Inputs/textEditor.vue";
*/
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import {Underline} from "@tiptap/extension-underline"
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
const editor = useEditor({
  editorProps: {
    attributes: {
      class: 'border border-gray-400 p-2 min-h-[12rem] max-h-[12rem] overflow-y-auto',
    },
  },
  content: "",
  extensions: [
    StarterKit,
    TextStyle,
    Underline,
    Color,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
  ],
})
</script>
<style>
ul {
  list-style-type: decimal !important;
}
h1, h2, h3, h4, h5, h6 {
  line-height: 1.1 !important;
  margin-top: 2.5rem !important;
  text-wrap: pretty !important;
}
h1, h2 {
  margin-top: 3.5rem !important;
  margin-bottom: 1.5rem !important;
}
h1 {
  font-size: 1.4rem !important;
}
h2 {
  font-size: 1.2rem !important;
}
h3 {
  font-size: 1.1rem !important;
}
h4, h5, h6 {
  font-size: 1rem !important;
}
</style>