<template>
  <div class="edit-product">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/products')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3 space-y-2">
      <text-input
          class=" lg:pe-3"
          label="Ürün Ad"
          is-required="true"
          v-model="product_name"
      />
      <text-input
          class=" lg:pe-3"
          label="Ürün Kodu"
          is-required="true"
          v-model="product_code"
      />
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <select-input
            label="Kategori"
            is-required="true"
            :options="categories"
            v-model="product_category"
            :disabled=true
        />
        <select-input
            label="Alt Kategori"
            is-required="true"
            :options="sub_categories"
            v-model="product_sub_category"
            :disabled=true
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <select-input
            label="Marka"
            is-required="true"
            :options="brands"
            v-model="product_brand"
        />
        <select-input
            label="Vergi Dilimi"
            is-required="true"
            :options="taxes"
            v-model="product_tax"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            label="Liste Fiyatı"
            v-model="product_list_price"
        />
        <text-input
            label="İskonto"
            v-model="product_discount"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-if="!product_list_price"
            label="Birim Fiyat"
            v-model="product_unit_price"
        />
        <text-input
            v-if="product_list_price"
            disabled="disabled"
            label="Birim Fiyat"
            v-model="local_unit_price"
        />
        <text-input
            label="Kargo Ücreti"
            v-model="product_transfer_fee"
        />
      </div>
      <div class="w-full lg:pe-3 space-y-3">
        <p class="text-sm font-medium text-slate-700 text-start">Ürün Açıklaması</p>
        <TiptapEditor v-model="product_desc" />
      </div>
      <button class="m-3 w-24 h-10 bg-orange-700 hover:bg-orange-600 w-32 text-gray-200 font-semibold rounded" @click="editItem">
        <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
        <span v-if="!isSpin">Güncelle</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import router from "@/router";
import {useRoute} from "vue-router";
import Swal from 'sweetalert2'
import TextInput from "@/components/Inputs/textInput.vue";
import SelectInput from "@/components/Inputs/selectInput.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import TiptapEditor from "@/components/Editor/tiptap.vue";
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const product_name = ref()
const product_brand = ref()
const product_tax = ref()
const product_category = ref()
const product_sub_category = ref()
const product_unit_price = ref()
const product_transfer_fee = ref()
const product_desc = ref()
const product_list_price = ref()
const product_discount = ref()
const product_code = ref()
const local_unit_price = ref()
const brands = ref()
const taxes = ref()
const categories = ref()
const sub_categories = ref()
watch(product_list_price, (newVal, oldVal) => {
  local_unit_price.value = newVal;
})
watch(product_discount, (newVal, oldVal) => {
  product_discount.value = newVal;
  local_unit_price.value = product_list_price.value - (product_list_price.value * product_discount.value / 100 );
})
async function getData(product_id = null) {
  if (product_id){
    router.push(`/edit-product/${product_id}`)
  }else{
    await axios.get(`https://service.bemekelektrik.com/api/v1/product/${route.params.id}`,{
      headers : {
        Authorization: `Bearer ${store.state.token}`
      }
    }).then(response => {
      product_name.value = response.data.name
      product_code.value = response.data.product_code ? response.data.product_code : null
      product_brand.value = response.data.brand_id
      product_tax.value = response.data.tax_id
      product_category.value = response.data.category_id ? response.data.category_id : null
      product_sub_category.value = response.data.sub_category_id ? response.data.sub_category_id : null
      product_unit_price.value = response.data.unit_price
      product_transfer_fee.value = response.data.transfer_fee
      product_list_price.value = response.data.list_price ? response.data.list_price : null
      product_discount.value = response.data.discount ? response.data.discount : null
      product_desc.value = response.data.desc
    }).catch(error => {
      Swal.fire({
        icon: 'error',
        text:error.response.data.message || ['Hata Oluştu!'],
        toast:true,
        position: "top-end",
        timer: 2000,
        timerProgressBar:true,
        showConfirmButton:false
      })
      router.push('/products');
    });
  }
}
async function getUsedData(){
  await axios.get(`https://service.bemekelektrik.com/api/v1/brand?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    brands.value = [];
    brands.value = response.data;
  })
  await axios.get(`https://service.bemekelektrik.com/api/v1/tax?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    taxes.value = []
    taxes.value = response.data
  })
  await axios.get(`https://service.bemekelektrik.com/api/v1/categories?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    categories.value = []
    categories.value = response.data
  })
  await axios.get(`https://service.bemekelektrik.com/api/v1/sub_categories?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    sub_categories.value = []
    sub_categories.value = response.data
  })
}
async function editItem() {
  isSpin.value = true
  let formData = new FormData();
  formData.append('name', product_name.value);
  if (product_category.value) {
    formData.append('category_id', product_category.value);
  }
  if (product_sub_category.value) {
    formData.append('sub_category_id', product_sub_category.value);
  }
  if (product_list_price.value){
    formData.append('list_price', product_list_price.value);
    formData.append('discount', product_discount.value);
  }else{
    formData.append('unit_price', product_unit_price.value);
  }
  formData.append('product_code', product_code.value);
  formData.append('brand_id', product_brand.value);
  formData.append('tax_id', product_tax.value);
  formData.append('unit_price', product_unit_price.value);
  formData.append('transfer_fee', product_transfer_fee.value);
  formData.append('desc', product_desc.value);
  formData.append("_method", "PUT");
  axios.post(`https://service.bemekelektrik.com/api/v1/product/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    Swal.fire({
      icon: 'success',
      text:response.data.message,
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    getData(response.data.product_id)
    getUsedData()
    isSpin.value = false
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  });
}
onMounted(() => {
  getData()
  getUsedData()
});
</script>
<style scoped>
</style>