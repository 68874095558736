<template>
  <div class="p-2 mt-8">
    <div class="loading mt-64" v-if="loading">
      <font-awesome-icon :icon="['fas', 'spinner']" class="text-blue-900 fa-spin text-3xl"/>
    </div>
    <div v-if="!loading" class="flex justify-end items-center p-2 space-x-2">
      <div class="w-60 flex items-center justify-between">
        <TextInput
            class=""
            v-if="search"
            :placeholder="searchPlaceholder"
            v-model="searchValue"/>
        <button class="w-12 h-10 border rounded bg-blue-950 text-gray-100 hover:text-gray-200"   v-if="search">
          <font-awesome-icon class="text-gray-100 text-lg" :icon="['fas', 'magnifying-glass']" @click="searchEmit"/>
        </button>
      </div>
      <button class="w-32 h-10 border rounded bg-blue-950 text-gray-100 hover:text-gray-200" v-if="addButton" @click="action('newItem')">
        <font-awesome-icon class="text-gray-100 text-lg" :icon="['fas', 'square-plus']" />
      </button>
    </div>
    <EasyDataTable
        v-if="!loading"
        :headers="headers"
        :items="items"
        table-class-name="customize-table"
        header-text-direction="center"
        body-text-direction="center"
        hide-footer
    >
      <template #item-image="item" v-if="imgArea">
        <div class="flex justify-center item-center">
          <img v-if="imgArea && imgGallery" :src="item[imgGallery] ? item[imgGallery] : ''" :alt="item[imgGallery]" width="100">
        </div>
      </template>
      <template #item-operation="item" v-if="operation">
        <div class="flex justify-center item-center space-x-3 text-lg">
          <font-awesome-icon v-if="operation && optStock" :icon="['fas', 'cubes']" class="cursor-pointer" @click="action('stock',item)"/>
          <font-awesome-icon v-if="operation && optShow" :icon="['fas', 'eye']" class="cursor-pointer" @click="action('show',item)"/>
          <font-awesome-icon v-if="operation && optEdit" :icon="['fa', 'pen-to-square']" class="cursor-pointer" @click="action('edit',item)"/>
          <font-awesome-icon v-if="operation && optDel" :icon="['fa', 'trash-can']" class="cursor-pointer" @click="action('delete',item)"/>
        </div>
      </template>
    </EasyDataTable>
    <div v-if="!loading" class="paginate mt-4">
      <div class="flex justify-end p-4">
        <div class="mx-3 cursor-pointer">
          <font-awesome-icon v-if="paginated.current_page === 1" class="paginate-icon" :icon="['fa', 'minus']" />
          <font-awesome-icon v-if="paginated.current_page !== 1" class="paginate-icon" :icon="['fa', 'square-caret-left']" @click="action('paginate',paginated.current_page - 1)"/>
        </div>
        <div class="">
          {{paginated.current_page}} / {{paginated.last_page}}
        </div>
        <div class="mx-3 cursor-pointer">
          <font-awesome-icon v-if="paginated.last_page !== paginated.current_page" class="paginate-icon" :icon="['fa', 'square-caret-right']" @click="action('paginate',paginated.current_page + 1)"/>
          <font-awesome-icon v-if="paginated.last_page === paginated.current_page" class="paginate-icon" :icon="['fa', 'minus']" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, watch} from "vue";
import TextInput from "@/components/Inputs/textInput.vue";
import EasyDataTable from "vue3-easy-data-table";
const props = defineProps({
  data:{},
  headers: {},
  operation:{
    default:false,
  },
  optDel:{
    default:false,
  },
  optEdit:{
    default:false,
  },
  optShow:{
    default:false,
  },
  optStock:{
    default:false,
  },
  addButton:{
    default:false,
  },
  search:{
    default:false,
  },
  searchArea:'',
  searchPlaceholder:"",
  imgArea:{
    default:false,
  },
  imgGallery:'',
})
const searchValue = ref("");
const loading = ref(true);
const paginated = ref({
  last_page: 0,
  per_page: 0,
  total: 0,
  current_page: 0,
  from: 0,
  to: 0,
});
const items = ref([])
watch(() => [props.data], ([newData]) => {
  if (newData) {
    paginated.value = {
      last_page: newData.last_page,
      per_page: newData.per_page,
      total: newData.total,
      current_page: newData.current_page,
      from: newData.from,
      to: newData.to,
    };
    items.value = newData.data;
    loading.value = false;
  }
},);
const emit = defineEmits();
const action = (action,item) => {
  const actionDetail = {
    action: action,
    item: item,
  }
  emit('action',actionDetail);  // Üst bileşene 'messageSent' olayı gönderiyoruz
};
function searchEmit(){
  emit('searchApi',searchValue);
}
</script>
<style scoped>

.customize-table{
  --easy-table-header-font-size: 14px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #2d3a4f;
  --easy-table-header-item-padding: 10px 15px;
  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;
  --easy-table-body-item-padding: 10px 15px;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;
}
</style>