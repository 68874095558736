<template>
  <div class="web-order-list">
    <breadcrumbs/>
    <dtable
        :headers="headers"
        :data="data"
        :operation=true
        :opt-del=false
        :opt-edit=true
        :opt-show=true
        :search=true
        search-area="shop_order_id"
        searchPlaceholder="Search Sipariş Kodu"
        :add-button=false
        :img-area=false
        img-gallery=''
        @action="action"
        @searchApi="searchApi"
    />
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import Dtable from "@/components/DTable/dtable.vue";
import router from "@/router";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import Swal from "sweetalert2";
const store = useStore();
const headers = ref([
  { text: "#", value: "id",width: 50},
  { text: "Sipariş Kodu", value: "shop_order_id",width: 150},
/*
  { text: "Kullanıcı", value: "users.name",width: 150},
*/
  { text: "Sipariş Durumu", value: "status",width: 150},
  { text: "Ödeme Tipi", value: "payment_type",width: 150},
  { text: "Tutar", value: "price",width: 150},
  { text: "Sipariş Tarihi", value: "created_at",width: 200},
  { text: "Operation", value: "operation", width:100}
]);
const data =ref()
async function getData() {
  await axios.get("https://service.bemekelektrik.com/api/v1/get_orders?page=1",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data
  });
}
function action(e) {
  if(e.action === 'newItem'){
  }
  if(e.action === 'show'){
    router.push(`show-web-order/${e.item.id}`)
  }
  if(e.action === 'edit'){
    router.push(`edit-web-order/${e.item.id}`)
  }
  if(e.action === 'delete'){
  }
  if (e.action === 'paginate'){
    axios.get(`https://service.bemekelektrik.com/api/v1/get_orders?page=${e.item}`,{
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    }).then((response) => {
      data.value = response.data
    })
  }
}
function searchApi(search) {
  let formData = new FormData()
  formData.append("shop_order_id", search.value)
  axios.post(`https://service.bemekelektrik.com/api/v1/search_order`, formData,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text: error.response.data.message || ['Hata Oluştu!'],
      toast: true,
      position: "top-end",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false
    })
  })
}
onMounted(() => {
  getData();
});
</script>
<style scoped></style>