<template>
  <div class="edit-web-order">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/web-return-orders')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3">
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-model="shop_order_id"
            label="Sipariş Kodu"
            disabled="true"
        />
        <select-input
            label="İade Durumu"
            is-required="true"
            :options="returnStatusData"
            v-model="return_status"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-model="return_created_at"
            label="İade Tarihi"
            disabled="true"
        />
        <text-input
            v-model="return_price"
            label="İade Tutarı"
            disabled="true"
        />
      </div>
      <button class="m-3 w-24 h-10 bg-orange-700 hover:bg-orange-600 w-32 text-gray-200 font-semibold rounded" @click="editItem">
        <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
        <span v-if="!isSpin">Güncelle</span>
      </button>
    </div>
    <div class="flex flex-col w-full my-4 lg:space-x-2 lg:pe-3" v-if="return_order_item">
      <p class="my-5" v-if="return_order_item">İade Ürünleri ( {{return_order_item.length}} )</p>
      <div class="product-main text-left mt-2" v-for="item in return_order_item">
        <div class="flex flex-col border p-4">
          <p>Ürün Adı: {{item.product[0].name}}</p>
          <p>İade Adeti: {{item.piece}}</p>
          <p>Ürün Fiyatı: {{Number(item.product[0].total_price)}} TL</p>
          <p>Kargo Fiyatı: {{Number(item.product[0].transfer_fee)}} TL</p>
          <p>İade Tutarı: <span class="text-red-400">{{ Number(item.product[0].total_price) * Number(item.piece) }} TL</span></p>
          <p>İade Nedeni: {{item.desc}}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <button @click="showOrder = !showOrder" class=" m-3 w-36 h-10 border bg-blue-950 hover:bg-blue-900 text-gray-200 font-semibold rounded">
        <span v-if="!showOrder">Siparişi Göster</span>
        <span v-if="showOrder">Siparişi Gizle</span>
      </button>
    </div>
    <div v-if="showOrder" class="border-4 border-blue-900 p-2 m-2 ">
      <div class="flex flex-col items-end add-area mt-3">
        <div class="flex flex-col justify-center lg:flex-row w-full lg:space-x-2 lg:pe-3">
          <text-input
              v-model="shop_order_id"
              class=" lg:pe-3"
              label="Sipariş Kodu"
              disabled="true"
          />
        </div>
        <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
          <text-input
              v-model="created_at"
              label="Sipariş Tarihi"
              disabled="true"
          />
          <text-input
              v-model="completed_at"
              label="Tamamlanma Tarihi"
              disabled="true"
          />
        </div>
        <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
          <text-input
              v-model="type"
              label="Sipariş Tipi"
              disabled="true"
          />
          <text-input
              v-model="payment_type"
              label="Ödeme Tipi"
              disabled="true"
          />
        </div>
        <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
          <text-input
              v-model="name"
              label="Ad Soyad"
              disabled="true"
          />
          <text-input
              v-model="email"
              label="Email"
              disabled="true"
          />
        </div>
        <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
          <text-input
              v-model="shipping_at"
              label="Kargolama Tarihi"
              disabled="true"
          />
          <text-input
              v-model="phone"
              label="Telefon"
              disabled="true"
          />
        </div>
        <text-input
            v-model="address"
            class=" lg:pe-3"
            label="Adres"
            disabled="true"
        />
        <div class="w-full lg:pe-3 space-y-3">
          <p class="text-sm font-medium text-slate-700 text-start">Sipariş Notu</p>
          <TiptapEditor v-model="order_desc"/>
        </div>
        <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
          <text-input
              v-model="transfer_price"
              label="Kargo Ücreti"
              disabled="true"
          />
          <text-input
              v-model="price"
              label="Toplam Ücret"
              disabled="true"
          />
        </div>
        <text-input
            v-model="status"
            label="Sipariş Durumu"
            disabled="true"
        />
        <text-input
            v-model="payment_status"
            label="Ödeme Durumu"
            disabled="true"
        />
        <text-input
            v-model="tracking_code"
            label="Kargo Takip Kodu"
            disabled="true"
        />
      </div>
      <div class="flex flex-col w-full my-4 lg:space-x-2 lg:pe-3" v-if="order_item">
        <p class="my-5" v-if="order_item">Sipariş Ürünleri ( {{order_item.length}} )</p>
        <div class="product-main text-left mt-2" v-for="item in order_item">
          <div class="flex flex-col border p-4" :class="item.return_piece !== 0 && item.return_piece !== null ? 'border-red-600' : ''">
            <p>Ürün Adı: {{item.product[0].name}}</p>
            <p>Ürün Adeti: {{item.piece}}</p>
            <p>Ürün Fiyatı: {{item.product[0].total_price}} TL (KDV Dahildir)</p>
            <p>Kargo Fiyatı: {{item.product[0].transfer_fee}} TL</p>
            <p>Toplam Fiyat: {{Number(item.product[0].total_price) + Number(item.product[0].transfer_fee)}} TL</p>
            <p class="text-red-600" v-if="item.return_piece !== 0 && item.return_piece !== null">İade Sayısı: {{item.return_piece}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import moment from 'moment';
import axios from "axios";
import router from "@/router";
import {useRoute} from "vue-router";
import Swal from 'sweetalert2'
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import TextInput from "@/components/Inputs/textInput.vue";
import SelectInput from "@/components/Inputs/selectInput.vue";
import TiptapEditor from "@/components/Editor/tiptap.vue";
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const return_price = ref()
const return_status = ref()
const return_created_at = ref()
const return_order_item = ref()
const shop_order_id = ref()
const created_at = ref()
const completed_at = ref()
const shipping_at = ref()
const payment_type = ref()
const type = ref()
const name = ref()
const email = ref()
const phone = ref()
const address = ref()
const price = ref()
const transfer_price = ref()
const status = ref()
const payment_status = ref()
const tracking_code = ref()
const order_desc = ref()
const order_item = ref()
const showOrder = ref(false)
const returnStatusData = ref([
  {id:'Beklemede',name:'Beklemede'},
  {id:'Onaylandı',name:'Onaylandı'},
  {id:'Reddedildi',name:'Reddedildi'},
  {id:'Değişim',name:'Değişim'}
])
const optionStatus = ref([
  {id:'Sipariş Alındı',name:'Sipariş Alındı'},
  {id:'Hazırlanıyor',name:'Hazırlanıyor'},
  {id:'Kargoya Verildi',name:'Kargoya Verildi'},
  {id:'Tamamlandı',name:'Tamamlandı'},
  {id:'İptal Edildi',name:'İptal Edildi'},
])
const optionPaymentStatus = ref([
  {id:'Ödeme Bekliyor',name:'Ödeme Bekliyor'},
  {id:'Ödendi',name:'Ödendi'},
  {id:'İade',name:'İade'},
])
async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/show_return_order/${route.params.id}`, {
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    return_price.value = response.data.return_price
    return_status.value = response.data.status
    return_created_at.value =  moment(response.data.created_at).format('DD/MM/YYYY')
    return_order_item.value =  response.data.return_order_items
    shop_order_id.value = response.data.orders.shop_order_id
    payment_type.value = response.data.orders.payment_type
    type.value = response.data.orders.type
    name.value = response.data.orders.user.name
    email.value = response.data.orders.user.email
    phone.value = response.data.orders.user.phone
    address.value = response.data.orders.order_adress.address
    price.value = response.data.orders.price
    transfer_price.value = response.data.orders.transfer_price
    status.value = response.data.orders.status
    payment_status.value = response.data.orders.payment_status
    tracking_code.value = response.data.orders.tracking_code
    order_desc.value = response.data.orders.order_desc
    created_at.value = moment(response.data.orders.created_at).format('DD/MM/YYYY')
    completed_at.value = response.data.orders.completed_at ? moment(response.data.orders.completed_at).format('DD/MM/YYYY') : '';
    shipping_at.value = response.data.orders.shipping_at ? moment(response.data.orders.shipping_at).format('DD/MM/YYYY') : '';
    order_item.value = response.data.orders.order_item
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    router.push('/web-return-orders');
  });
}
async function editItem() {
  isSpin.value = true
  let formData = new FormData();
  formData.append('status', return_status.value)
  axios.post(`https://service.bemekelektrik.com/api/v1/update_return_order/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    Swal.fire({
      icon: 'success',
      text:response.data,
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    getData()
    isSpin.value = false
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  });
}
onMounted(() => {
  getData()
});
</script>
<style scoped>
</style>