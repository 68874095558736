<template>
  <div class="product-list">
    <breadcrumbs/>
    <dtable
        :headers="headers"
        :data="data"
        :operation=true
        :opt-del=true
        :opt-edit=true
        :opt-show=true
        :opt-stock="true"
        :search=true
        search-area="name"
        searchPlaceholder="Search Ad"
        :add-button=true
        :img-area=true
        img-gallery='image'
        @action="action"
        @searchApi="searchApi"
    />
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import Dtable from "@/components/DTable/dtable.vue";
import router from "@/router";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import Swal from "sweetalert2";
const store = useStore();
const headers = ref([
  { text: "#", value: "id",width: 50},
  { text: "Ad", value: "name"},
  { text: "Ürün Kodu", value: "product_code",width: 100},
  { text: "Stok", value: "product_stocks.stock",width: 100},
  { text: "Marka", value: "brands.name",width: 100},
  { text: "Kategori", value: "categories.name",width: 100},
  { text: "Alt Kategori", value: "sub_categories.name",width: 100},
  { text: "Vergi Dilimi", value: "taxes.name",width: 50},
  { text: "Birim Fiyatı", value: "unit_price",width: 50},
  { text: "Toplam Ücret", value: "total_price",width: 50},
  { text: "Kargo Ücreti", value: "transfer_fee",width: 50},
  { text: "Operation", value: "operation", width:200}
]);
const data =ref()
async function getData() {
  await axios.get("https://service.bemekelektrik.com/api/v1/product?page=1",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data
  });
}
function action(e) {
  if(e.action === 'newItem'){
    router.push('/new-product')
  }
  if(e.action === 'show'){
    router.push(`show-product/${e.item.id}`)
  }
  if(e.action === 'edit'){
    router.push(`edit-product/${e.item.id}`)
  }
  if(e.action === 'delete'){
    delItem(e.item.id)
  }
  if (e.action === 'stock'){
    router.push(`/edit-product-stock/${e.item.id}`)
  }
  if (e.action === 'paginate'){
    axios.get(`https://service.bemekelektrik.com/api/v1/product?page=${e.item}`,{
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    }).then((response) => {
      data.value = response.data
    })
  }
}
function delItem(id) {
  Swal.fire({
    title: "",
    text: "Veri Kaybına Neden Olabilir! İşleme Devam Etmek İstiyor Musunuz?",
    icon: "warning",
    width: '40%',
    showCancelButton: true,
    confirmButtonColor: "#6A42C2",
    cancelButtonColor: "#C62E2E",
    confirmButtonText: "Evet",
    cancelButtonText: 'Hayır'
  }).then((result) => {
    if (result.isConfirmed === true) {
      axios.delete(`https://service.bemekelektrik.com/api/v1/product/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      }).then((response) => {
        Swal.fire({
          icon: 'success',
          text: response.data,
          toast: true,
          position: "top-end",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false
        })
        getData()
      }).catch(error => {
        Swal.fire({
          icon: 'error',
          text: error.response.data.message || ['Hata Oluştu!'],
          toast: true,
          position: "top-end",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false
        })
      })
    }
  })
}
function searchApi(search) {
  let formData = new FormData()
  formData.append("search", search.value)
  axios.post(`https://service.bemekelektrik.com/api/v1/search_product`, formData,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text: error.response.data.message || ['Hata Oluştu!'],
      toast: true,
      position: "top-end",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false
    })
  })
}
onMounted(() => {
  getData();
});
</script>
<style scoped></style>