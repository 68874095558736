<template>
  <div class="category-list">
    <breadcrumbs/>
    <dtable
        :headers="headers"
        :data="data"
        :operation=true
        :opt-del=true
        :opt-edit=true
        :opt-show=false
        :search=false
        search-area="name"
        searchPlaceholder="Search Ad"
        :add-button=true
        :img-area=false
        img-gallery=''
        @action="action"
    />
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import Dtable from "@/components/DTable/dtable.vue";
import router from "@/router";
import Swal from "sweetalert2";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
const store = useStore();
const headers = ref([
  { text: "#", value: "id",width: 50},
  { text: "Ad", value: "name"},
  { text: "Operation", value: "operation", width:200}
]);
const data =ref()
async function getData() {
  await axios.get("https://service.bemekelektrik.com/api/v1/categories?page=1",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data
  });
}
function action(e) {
  if(e.action === 'newItem'){
    router.push('/new-category')
  }
  if(e.action === 'show'){
  }
  if(e.action === 'edit'){
    router.push(`/edit-category/${e.item.id}`);
  }
  if(e.action === 'delete'){
    delItem(e.item.id)
  }
  if (e.action === 'paginate'){
    axios.get(`https://service.bemekelektrik.com/api/v1/categories?page=${e.item}`,{
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    }).then((response) => {
      data.value = response.data
    })
  }
}
function delItem(id){
  Swal.fire({
    title: "",
    text: "Veri Kaybına Neden Olabilir! İşleme Devam Etmek İstiyor Musunuz?",
    icon: "warning",
    width:'40%',
    showCancelButton: true,
    confirmButtonColor: "#6A42C2",
    cancelButtonColor: "#C62E2E",
    confirmButtonText: "Evet",
    cancelButtonText:'Hayır'
  }).then((result) => {
    if (result.isConfirmed === true) {
      axios.delete(`https://service.bemekelektrik.com/api/v1/categories/${id}`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      }).then((response) => {
        Swal.fire({
          icon: 'success',
          text:response.data,
          toast:true,
          position: "top-end",
          timer: 2000,
          timerProgressBar:true,
          showConfirmButton:false
        })
        getData()
      }).catch(error => {
        Swal.fire({
          icon: 'error',
          text:error.response.data.message || ['Hata Oluştu!'],
          toast:true,
          position: "top-end",
          timer: 2000,
          timerProgressBar:true,
          showConfirmButton:false
        })
      })
    }
  })
}
onMounted(() => {
  getData();
});
</script>
<style scoped>

</style>