<template>
  <div class="new-category">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/contracts')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3">
      <text-input
          class=" lg:pe-3"
          label="Sözleşme Adı"
          is-required="true"
          v-model="name"
      />
      <div class="w-full lg:pe-3 space-y-3">
        <p class="text-sm font-medium text-slate-700 text-start">Sözleşme İçeriği</p>
        <TiptapEditor v-model="contract" />
      </div>
      <button class="m-3 w-24 h-10 bg-blue-900 hover:bg-blue-800 w-32 text-gray-200 font-semibold rounded" @click="addItem">
        <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
        <span v-if="!isSpin">Ekle</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import axios from "axios";
import router from "@/router";
import {useStore} from "vuex";
const store = useStore();
import Swal from 'sweetalert2'
import TextInput from "@/components/Inputs/textInput.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue"
import TiptapEditor from '../../../components/Editor/tiptap.vue'
const isSpin = ref(false)
const name = ref('')
const contract = ref('')
function addItem() {
  isSpin.value = true
  let formData = new FormData()
  formData.append('name',name.value)
  formData.append('contract',contract.value)
  axios.post('https://service.bemekelektrik.com/api/v1/contracts',formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    Swal.fire({
      icon: 'success',
      text:response.data,
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    router.push('/contracts')
    isSpin.value = false
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  });
}
</script>
<style scoped>

</style>